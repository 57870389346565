<template>
  <div class="wechat">
    <img class="gold" src="@/assets/img/wechat/gold.png" />
    <p>权益已发放</p>
    <div class="txt">通过<span>易点生活权益中心</span>兑换</div>
    <div class="box">
      <img src="@/assets/img/wechat/logo.png" />
      <p>易点生活权益中心</p>
      <div class="wechatTxt">微信小程序</div>
      <div class="boxBtn" @click="openwechat()">
        <img src="@/assets/img/wechat/btn1.png" />
        <div>打开微信小程序</div>
        <img class="btn2" src="@/assets/img/wechat/btn2.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { getWxLink } from '@/api/wechat.js'
export default {
  data() {
    return {}
  },
  created() {},
  methods: {
    async openwechat() {
      const { code: redeemCode } = this.$route.params
      // console.log(encodeURIComponent(redeemCode))
      const { code, data } = await getWxLink({ redeemCode: redeemCode })
      if (code === 0) {
        window.location.href = data
      }
    }
  }
}
</script>
<style lang="less" scoped>
.wechat {
  min-height: 100vh;
  background: url('~@/assets/img/wechat/bg.png') center center no-repeat;
  background-size: cover;
  font-size: 28px;
  color: #000001;
  text-align: center;
  .gold {
    width: 129px;
    display: block;
    margin: auto;
    padding: 116px 0 40px;
  }
  .txt {
    font-weight: 600;
    font-size: 36px;
    padding: 20px 0 134px;
    span {
      color: #033ad6;
    }
  }
  .box {
    background: #ffffff;
    border-radius: 20px;
    width: 640px;
    margin: auto;
    box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0.5);
    font-size: 32px;
    color: #000000;
    padding: 80px 0 106px;
    & > img {
      width: 140px;
      display: block;
      margin: 0px auto 25px;
    }
    .wechatTxt {
      width: 165px;
      // height: 44px;
      // line-height: 44px;
      margin: 22px auto 60px;
      padding: 6px 0;
      font-size: 22px;
      color: #cacaca;
      border-radius: 22px;
      border: 1px solid #cacaca;
    }
    .boxBtn {
      width: 580px;
      height: 90px;
      background: linear-gradient(0deg, #000000, #000000);
      border-radius: 45px;
      font-size: 32px;
      color: #eacc91;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 0px;
      & > img {
        width: 36px;
        margin-right: 12px;
      }
      .btn2 {
        width: 12px;
        margin-left: 12px;
      }
    }
  }
}
</style>
